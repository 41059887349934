import React from "react";
import { AuthorDetail } from "@pages";
import { GetServerDataProps, GetServerDataReturn, PageProps } from "gatsby";
import { Queries } from "@graphql-client";
import { IAuthorGQL } from "@interfaces";
import { Author, IAuthor } from "@models";
import { GraphqlUtil } from "@utils";

interface IServerData {
  data: IAuthor | null;
}

interface IServerDataProps extends GetServerDataProps {
  params: {
    slug: string;
  };
}

interface IProps extends PageProps {
  serverData: IServerData;
}

interface IPageQueryData {
  teamBySlug: {
    data: IAuthorGQL;
  };
}

const AuthorDetailPage = ({ serverData: { data } }: IProps) => {
  return <AuthorDetail author={data} />;
};

export async function getServerData({
  params: { slug },
}: IServerDataProps): GetServerDataReturn<IServerData> {
  try {
    const { data } = await GraphqlUtil.queryWithCache<IPageQueryData>({
      query: Queries.GET_AUTHOR_BY_SLUG,
      key: `${slug}_author_expiration_query`,
      expiration: "LONG",
      options: {
        variables: { slug },
      },
    });

    if (!data || !data?.teamBySlug) {
      return {
        status: 404,
        props: {
          data: null,
        },
      };
    }

    return {
      status: 200,
      props: {
        data: Author(data.teamBySlug.data),
      },
    };
  } catch (error) {
    return {
      status: 500,
      headers: {},
      props: {
        data: null,
      },
    };
  }
}

export default AuthorDetailPage;
